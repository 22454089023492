export const planTypes = [
  { label: 'Advanced', value: 'Advanced', id: '1' },
  { label: 'Basic', value: 'Basic', id: '2' },
  { label: 'Pro', value: 'Pro', id: '3' },
  { label: 'Smart Plus Basic', value: 'Smart Plus Basic', id: '4' },
  { label: 'Smart Plus Advanced', value: 'Smart Plus Advanced', id: '5' },
  { label: 'Smart Plus Pro', value: 'Smart Plus Pro', id: '6' },
];

export const planTimeDuration = [
  { label: 'Monthly', value: 'Monthly', id: '1' },
  { label: 'Quartly', value: 'Quartly', id: '2' },
  { label: 'Semi-Annually', value: 'Semi-Annually', id: '3' },
  { label: 'Annually', value: 'Annually', id: '4' },
];
