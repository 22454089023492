const truckTypes = [
    { label: "32 Feet Single Axle", value: "32 Feet Single Axle", truck_id:"1" },
    { label: "32 Feet Single Axle High Cube", value: "32 Feet Single Axle High Cube", truck_id:"2" },
    { label: "32 Feet Multi Axle", value: "32 Feet Multi Axle", truck_id:"3" },
    { label: "32 Feet Multi Axle High Cube", value: "32 Feet Multi Axle High Cube", truck_id:"4" },
    { label: "32 Feet Triple Axle", value: "32 Feet Triple Axle", truck_id:"5" },
    { label: "22 Feet", value: "22 Feet", truck_id:"6" },
    { label: "24 Feet", value: "24 Feet", truck_id:"7" },
    { label: "20 Feet", value: "20 Feet", truck_id:"8" },
    { label: "22 Feet Open", value: "22 Feet Open", truck_id:"9" },
    { label: "10 Whl", value: "10 Whl", truck_id:"10" },
    { label: "24 Feet Open", value: "24 Feet Open", truck_id:"11" },
    { label: "20 Feet Open", value: "20 Feet Open", truck_id:"12" },
    { label: "17 Feet Open", value: "17 Feet Open", truck_id:"13" },
    { label: "12 Whl", value: "12 Whl", truck_id:"14" },
    { label: "14 Whl", value: "14 Whl", truck_id:"15" }
];

const materialTypes = [
    { label: "Agriculture", value: "Agriculture", material_id:"1" },
    { label: "Alcoholic Beverage", value: "Alcoholic Beverage", material_id:"2" },
    { label: "Auto Parts", value: "Auto Parts", material_id:"3" },
    { label: "Bags", value: "Bags", material_id:"4" },
    { label: "Beverages", value: "Beverages", material_id:"5" },
    { label: "Bundle / Rolls", value: "Bundle / Rolls", material_id:"6" },
    { label: "Carton Box", value: "Carton Box", material_id:"7" },
    { label: "Carton box / FMCG", value: "Carton box / FMCG", material_id:"8" },
    { label: "Drum/barrel", value: "Drum/barrel", material_id:"9" },
    { label: "Electronic Goods", value: "Electronic Goods", material_id:"10" },
    { label: "Empty Bottle", value: "Empty Bottle", material_id:"11" },
    { label: "Empty Cylinder", value: "Empty Cylinder", material_id:"12" },
    { label: "Engineering materials", value: "Engineering materials", material_id:"13" },
    { label: "FMCG", value: "FMCG",material_id:"14" },
    { label: "Footwears", value: "Footwears",material_id:"15" },
    { label: "Furniture", value: "Furniture",material_id:"16" },
    { label: "Garment bundle", value: "Garment bundle",material_id:"17" },
    { label: "Glass", value: "Glass",material_id:"18" },
    { label: "Hardware (Door / Windows)", value: "Hardware (Door / Windows)",material_id:"19" },
    { label: "Hardware (Pipes / Tiles)", value: "Hardware (Pipes / Tiles)",material_id:"20" },
    { label: "Industrial Goods", value: "Industrial Goods",material_id:"21" },
    { label: "Kitchenware", value: "Kitchenware",material_id:"22" },
    { label: "Medicine", value: "Medicine",material_id:"23" },
    { label: "Milk Products", value: "Milk Products",material_id:"24" },
    { label: "Others", value: "Others",material_id:"25" },
    { label: "Packaging Materials", value: "Packaging Materials",material_id:"26" },
    { label: "Paint", value: "Paint",material_id:"27" },
    { label: "Pallet / Panel", value: "Pallet / Panel",material_id:"28" },
    { label: "Paper Bundle", value: "Paper Bundle",material_id:"29" },
    { label: "Parcel", value: "Parcel",material_id:"30" },
    { label: "Plywood", value: "Plywood",material_id:"31" },
    { label: "Sack (Jute/Plastic)", value: "Sack (Jute/Plastic)", material_id:"32" },
    { label: "Scrap", value: "Scrap", material_id:"33" },
    { label: "Solid Sheets", value: "Solid Sheets", material_id:"34" },
    { label: "Textiles", value: "Textiles", material_id:"35" },
    { label: "Tyre", value: "Tyre", material_id:"36" },
    { label: "Waste Cloths", value: "Waste Cloths", material_id:"37" },
    { label: "Waste Material", value: "Waste Material", material_id:"38" }
];

const weights = [
    { weights_id:"1", label: "5", value: 5 },
    { weights_id:"2", label: "6", value: 6 },
    { weights_id:"3", label: "6.5", value: 6.5 },
    { weights_id:"4", label: "7", value: 7 },
    { weights_id:"5", label: "7.5", value: 7.5 },
    { weights_id:"6", label: "8", value: 8 },
    { weights_id:"7", label: "9", value: 9 },
    { weights_id:"8", label: "10", value: 10 },
    { weights_id:"9", label: "11", value: 11 },
    { weights_id:"10", label: "12", value: 12 },
    { weights_id:"11", label: "13", value: 13 },
    { weights_id:"12", label: "14", value: 14 },
    { weights_id:"13", label: "15", value: 15 },
    { weights_id:"14", label: "16", value: 16 },
    { weights_id:"15", label: "17", value: 17 },
    { weights_id:"16", label: "18", value: 18 },
    { weights_id:"17", label: "19", value: 19 },
    { weights_id:"18", label: "20", value: 20 },
    { weights_id:"19", label: "21", value: 21 },
    { weights_id:"20", label: "22", value: 22 },
    { weights_id:"21", label: "23", value: 23 },
    { weights_id:"22", label: "24", value: 24 },
    { weights_id:"23", label: "25", value: 25 },
    { weights_id:"24", label: "26", value: 26 },
    { weights_id:"25", label: "27", value: 27 },
    { weights_id:"26", label: "28", value: 28 },
    { weights_id:"27", label: "29", value: 29 },
    { weights_id:"28", label: "30", value: 30 },
    { weights_id:"29", label: "31", value: 31 },
    { weights_id:"30", label: "32", value: 32 }
];

const weightTypes = [
    {label:"Ton",value:"Ton"},
    {label:"Kg",value:"Kg"}
]

module.exports = {
    truckTypes: truckTypes,
    materialTypes: materialTypes,
    weights: weights,
    weightTypes:weightTypes
}